export const useCookies = () => {
  const setCookie = (
    name: string,
    value: string,
    expire = 7, // Nb of days or timestamp in seconds
    path = "/",
    domain = "",
    secure = false,
    sameSite = "Lax",
  ) => {
    let cookie = name + "=" + encodeURIComponent(value);
    if (expire) {
      const date = new Date();
      if (expire <= 365 && expire > 0) {
        date.setTime(date.getTime() + expire * 24 * 60 * 60 * 1000);
      } else {
        date.setTime(expire * 1000);
      }
      cookie += "; expires=" + date.toUTCString();
    }
    if (path) {
      cookie += "; path=" + path;
    }
    if (domain) {
      cookie += "; domain=" + domain;
    }
    if (secure) {
      cookie += "; secure";
    }
    if (sameSite) {
      cookie += "; samesite=" + sameSite;
    }
    document.cookie = cookie;
  };
  const getCookie = (name: string) => {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");
    if (parts.length === 2) {
      const cookie = decodeURIComponent(parts.pop()!.split(";").shift()!);
      return cookie !== "" ? cookie : null;
    }
    return null;
  };
  const deleteCookie = (
    name: string,
    path = "/",
    domain = "",
    secure = false,
    sameSite = "Lax",
  ) => {
    setCookie(name, "", 0, path, domain, secure, sameSite);
  };
  return {
    setCookie,
    getCookie,
    deleteCookie,
  };
};

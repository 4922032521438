<template>
  <div class="h-screen w-screen bg-gray-100 flex items-center justify-center">
    <div
      class="container flex flex-col md:flex-row items-center justify-center px-5 text-gray-700"
    >
      <div class="max-w-md">
        <div class="text-5xl font-dark font-bold">
          {{ t("common.errors.404_error") }}
        </div>
        <p class="text-2xl md:text-3xl font-light leading-normal">
          {{ t("common.errors.404_error_message") }}
        </p>
        <p class="mb-8">{{ t("common.errors.404_error_message2") }}</p>

        <router-link
          to="/"
          class="px-4 inline py-2 text-sm font-medium leading-5 shadow text-white transition-colors duration-150 border border-transparent rounded-lg focus:outline-none focus:shadow-outline-blue bg-blue-600 active:bg-blue-600 hover:bg-blue-700"
          >{{ t("common.back_to_home") }}</router-link
        >
      </div>
      <div class="max-w-lg">
        <img
          class="w-5/6"
          src="/assets/images/logo/spectrum.svg"
          alt="Logo Spectrum"
        />
      </div>
    </div>
  </div>
  <MinimalFooter :version="appVersion" />
</template>

<script setup lang="ts">
import { inject } from "vue";
import { useI18n } from "vue-i18n";
import MinimalFooter from "../components/MinimalFooter.vue";

const appVersion = inject<string>("version", "");
const { t } = useI18n();
</script>

<style scoped></style>

import axios from "axios";
import { nextTick } from "vue";
import { createI18n, I18n } from "vue-i18n";

export interface MessageObject {
  [key: string]: string | MessageObject;
}

type MessageObjectFunction = () => Promise<MessageObject>;

export const LOCALES = {
  fr: "fr-FR",
  en: "en-US",
} as const;

export type LocalesNames = Record<keyof typeof LOCALES, string>;

export const LOCALES_NAMES: LocalesNames = {
  fr: "Français",
  en: "English",
};

export const SUPPORT_LOCALES = Object.keys(LOCALES);
export const DEFAULT_LOCALE = "fr";
export const LOCALES_FILES = import.meta.glob("./locales/**/*.json");
const SHARED_SPECTRUM_LOCALES_FILES = import.meta.glob(
  "../../node_modules/i18n-shared-spectrum/locales/**/*.json",
);

class LangFiles {
  static files: Record<string, MessageObjectFunction> = {};
  static mergeGlobs(
    globFiles: Record<string, () => Promise<unknown>>,
    prefix = "",
  ) {
    for (const path of Object.keys(globFiles)) {
      const pathNames = path.split("/");
      const componentName = pathNames.pop();
      if (componentName === undefined || !componentName.endsWith(".json")) {
        continue;
      }
      pathNames.push(prefix + componentName);
      this.files[pathNames.join("/")] = globFiles[
        path
      ] as MessageObjectFunction;
    }
  }

  static retrieveLocaleFiles(locale: string) {
    return Object.entries(this.files)
      .filter(([path]) => path.split("/").lastIndexOf(locale) !== -1)
      .map(
        ([path, importFn]) =>
          [path.split("/").pop()!.replace(".json", ""), importFn] as const,
      );
  }
}

LangFiles.mergeGlobs(LOCALES_FILES);
LangFiles.mergeGlobs(SHARED_SPECTRUM_LOCALES_FILES, "shared_spectrum:");

const datetimeFormats: {
  [key in keyof typeof LOCALES]: Record<string, Record<string, string>>;
} = {
  en: {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    long: {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    },
  },
  fr: {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    long: {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    },
  },
};

export const i18n = createI18n<false>(
  Object.assign(
    {
      locale: DEFAULT_LOCALE,
      fallbackLocale: ["en", "fr"],
    },
    {
      legacy: false,
      datetimeFormats,
    },
  ),
);

export function setI18nLanguage(i18n: any, locale: string) {
  const i18nInstance = i18n.global ?? i18n;
  if (i18nInstance.mode === "legacy") {
    i18nInstance.locale = locale;
  } else {
    i18nInstance.locale.value = locale;
  }
  axios.defaults.headers.common["Accept-Language"] = locale;
  document.querySelector("html")!.setAttribute("lang", locale);
}

const localesLoading: Record<string, boolean> = {};

export async function loadLocaleMessages(
  i18nGlobal: I18n["global"],
  locale: string,
) {
  // check if locale already loading/loaded or not
  if (locale in localesLoading) {
    return;
  }
  localesLoading[locale] = true;

  const importedMessages = await Promise.all(
    LangFiles.retrieveLocaleFiles(locale).map(([path, importFn]) =>
      importFn().then((messages) => ({ [path]: messages.default })),
    ),
  );

  // load locale messages with dynamic import
  const messages: MessageObject = importedMessages.reduce(
    (acc, cur) => Object.assign(acc, cur),
    {},
  );

  // set locale and locale message
  i18nGlobal.setLocaleMessage(locale, messages);

  await nextTick();
}

import { api } from "./api";
import type {
  ICryptoPost,
  ICryptoResponse,
  CryptoObject,
} from "./crypto.types";

export const postCrypto = async (crypto: ICryptoPost) => {
  const { data } = await api.post("/cryptos", crypto);
  return data;
};

export const getCrypto = async (object: CryptoObject, uuid: string) => {
  const { data } = await api.get<ICryptoResponse>(`/cryptos/${object}/${uuid}`);
  return data;
};

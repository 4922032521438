import { type RouteRecordRaw } from "vue-router";
import { renameNameRecursive } from "../helpers";
import { requireAuth } from "../requireAuth";
import { requireRoles } from "../requireRoles";
import { requireUrlToken } from "../requireUrlToken";
import { organisationRoutes } from "./organisations";
import { patientRoutes } from "./patients";
import { practitionerRoutes } from "./practitioners";
import { protocolRoutes } from "./protocols";
import { runRoutes } from "./runs";
import { userRoutes } from "./users";

export const mainRoutes: RouteRecordRaw[] = [
  //
  // Auth required
  //
  {
    path: "",
    component: async () => await import("../../layouts/LoggedLayout.vue"),
    children: [
      {
        name: "Home",
        path: "",
        component: async () => await import("../../views/HomeDashboard.vue"),
      },
      {
        path: "patient",
        children: renameNameRecursive("Patient", patientRoutes),
        meta: {
          middlewares: [requireRoles(["practitioner", "global_admin"])],
        },
      },
      {
        path: "protocol",
        children: renameNameRecursive("Protocol", protocolRoutes),
        meta: {
          middlewares: [requireRoles(["practitioner", "global_admin"])],
        },
      },
      {
        path: "user",
        children: renameNameRecursive("User", userRoutes),
      },
      {
        path: "organisation",
        children: renameNameRecursive("Organisation", organisationRoutes),
        meta: {
          middlewares: [requireRoles(["organiser", "global_admin"])],
        },
      },
      {
        path: "practitioner",
        children: renameNameRecursive("Practitioner", practitionerRoutes),
      },
      {
        path: "run",
        children: renameNameRecursive("Run", runRoutes),
      },
      {
        path: "test-error",
        component: async () => await import("../../views/TestError.vue"),
      },
    ],
    meta: {
      middlewares: [requireAuth],
    },
  },

  //
  // Auth not required
  //
  {
    name: "Login",
    path: "login",
    component: async () => await import("../../views/Login.vue"),
  },
  {
    path: "password",
    children: [
      {
        name: "ResetPassword",
        path: "reset",
        component: async () => await import("../../views/ResetPassword.vue"),
        meta: {
          middlewares: [requireUrlToken()],
        },
      },
      {
        name: "LostPassword",
        path: "lost",
        component: async () => await import("../../views/LostPassword.vue"),
      },
    ],
  },
  {
    name: "CookiePolicy",
    path: "cookie-policy",
    component: async () => await import("../../views/CookiePolicy.vue"),
  },
  {
    name: "CGU",
    path: "cgu",
    component: async () => await import("../../views/Cgu.vue"),
  },
  {
    name: "CGUForms",
    path: "cgu-ogames",
    component: async () => await import("../../views/CguForms.vue"),
  },
  {
    name: "CGV",
    path: "cgv",
    component: async () => await import("../../views/Cgv.vue"),
  },
  {
    name: "PrivacyPolicy",
    path: "privacy-policy",
    component: async () => await import("../../views/PrivacyPolicy.vue"),
  },
  {
    name: "TermsAndConditions",
    path: "terms-and-conditions",
    component: async () => await import("../../views/TermsAndConditions.vue"),
  },
];

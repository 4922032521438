import Hex from "crypto-js/enc-hex";
import { decryptObject, encryptObject } from "../helpers/crypto";
import { api } from "./api";
import {
  type IContactMinimal,
  type IContactResponse,
  type IContact,
  type IContactEncryptedData,
  type IContactPost,
  type IContactPut,
  type IContactRawResponse,
} from "./contact.types";
import { getCrypto } from "./crypto";

export const getContact = async (
  contactUUid: string
): Promise<IContactResponse> => {
  const { key } = await getCrypto("contact", contactUUid);
  const [k, iv] = key.split(":");
  const { data } = await api.get<IContactRawResponse>(
    `/contact/${contactUUid}`
  );
  const contact = await decodeContact<IContactRawResponse>(data, k, iv);
  return contact;
};

export const putContact = async (
  contactUUid: string,
  contact: IContactMinimal
) => {
  const { key } = await getCrypto("contact", contactUUid);
  const [k, iv] = key.split(":");
  const contactEncrypted = await encodeContact(contact, k, iv);
  const response = await api.put(
    `/contact/${contactUUid}`,
    contactEncrypted.contact
  );
  return response.data;
};

export const encodeContact = async <T extends IContactMinimal | IContact>(
  contact: T,
  passphrase: string,
  iv = ""
): Promise<{
  contact: IContactPost | IContactPut;
  key: string;
  iv: string;
}> => {
  const {
    email,
    firstname,
    name,
    phone,
    attributes,
    img,
    address,
    city,
    postalCode,
    tutorName,
    tutorFirstname,
    ...rest
  } = contact;
  const contactEncrypted = await encryptObject<IContactEncryptedData>(
    {
      email,
      firstname,
      name,
      phone,
      attributes,
      img,
      address,
      city,
      postalCode,
      tutorName,
      tutorFirstname,
    },
    passphrase,
    iv
  );
  return {
    contact: {
      ...rest,
      contactEncrypted: Hex.stringify(contactEncrypted.ciphertext),
    },
    key: Hex.stringify(contactEncrypted.key),
    iv: Hex.stringify(contactEncrypted.iv),
  };
};

export const decodeContact = async <
  T extends IContactPost | IContactPut | IContactRawResponse
>(
  contact: T,
  key: string,
  iv: string
): Promise<Omit<T, "contactEncrypted"> & IContactEncryptedData> => {
  const { contactEncrypted, ...rest } = contact;
  const contactDecrypted = await decryptObject<IContactEncryptedData>(
    contactEncrypted,
    key,
    iv
  );
  return {
    ...rest,
    ...contactDecrypted,
  };
};

import { type RouteRecordRaw } from "vue-router";

export const renameNameRecursive = (prefix: string, routes: RouteRecordRaw[]) =>
  routes.map(
    (route: RouteRecordRaw): RouteRecordRaw =>
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      ({
        ...route,
        name:
          route.name !== undefined
            ? `${prefix}.${String(route.name)}`
            : undefined,
        children: route.children
          ? renameNameRecursive(prefix, route.children)
          : undefined,
      } as RouteRecordRaw)
  );

export interface IGenericMessage {
  message: string;
  details: string[];
}

export type IListResponse<K extends string, T> = {
  [P in K]: T[];
} & { count: string };

export interface IErrorMessage extends IGenericMessage {
  error: string;
  code: number;
}

export interface ILoginResponse {
  token: string;
  sessionSignSecret: string;
  MFAOTPRequired: boolean;
}

export interface ILoginTokenResponse {
  uuid: string;
  login: string;
  exp: number;
  aud: string;
}

export enum ErrorCodes {
  NotLoggedIn = 16,
}

export interface IEntityResponse {
  altname: string;
  creation: string;
  img: string;
  name: string;
  nameref: string;
  nbAccounts: string;
  nbArtefacts: string;
  nbContacts: string;
  nbEntities: string;
  nbParticipants: string;
  nbServices: string;
  nbStudies: string;
  ownerUuid: string;
  status: string;
  uuid: string;
}

export interface IEntitiesResponse {
  entities: IEntityResponse[];
  count: number;
}

export interface IStudyResponse {
  attributes: string;
  creation: string;
  label: string;
  nameref: string;
  status: string;
  uuid: string;
  uuidEntity: string;
}

export interface IStudiesResponse {
  services: IStudyResponse[];
  count: number;
}

export interface IPasswordResetResponse {}

export interface ICtbResponse {
  adminStatus: string;
  attributes: string;
  bypassElig: boolean;
  creation: string;
  description: string;
  img: string;
  label: string;
  maxParticipants: number;
  nameref: string;
  opStatus: string;
  strictChallengesIndexes: boolean;
  uuid: string;
  uuidOwner: string;
  uuidParent: string;
  uuidTypeCtb: string;
}

export interface IFormAnswerResponse {
  answers: string;
  creation: string;
  index: string;
  modification: string;
  nameref: string;
  scoring: string;
  status: string;
  url: string;
  uuid: string;
  uuidParticipant: string;
  uuidTypeForm: string;
}

export interface IFormAnswersResponse {
  forms: IFormAnswerResponse[];
  count: number;
}

export interface IBasicPostResponse {
  uuid: string;
}

export type FormulaVariable = string | number | Formula;

export interface Scoring {
  uuid: string;
  name: string;
  questions: string[];
  domain: string[];
}

export interface Questionnaire {
  uuid: string;
  name: string;
  description: string;
  collection: string;
  runTime: string;
  ageRange: string;
  scorings: Scoring[];
}

export interface Formula {
  method: string;
  variables: FormulaVariable[];
  parent?: string;
  required_variables?: string[];
  required_questions?: string[];
}

export type RequireOnlyOne<T, Keys extends keyof T = keyof T> = Pick<
  T,
  Exclude<keyof T, Keys>
> &
  {
    [K in Keys]-?: Required<Pick<T, K>> &
      Partial<Record<Exclude<Keys, K>, undefined>>;
  }[Keys];

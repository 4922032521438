import AES from "crypto-js/aes";
import Hex from "crypto-js/enc-hex";
import Utf8 from "crypto-js/enc-utf8";
import { random } from "crypto-js/lib-typedarrays";

/**
 * Encrypts a string
 * @rgpd This function is used to encrypt data for the API
 * @param data string to encrypt
 * @param passphrase string to use as key
 * @param iv string to use as iv
 * @returns encrypted string
 */
export const encrypt = async (data: string, passphrase: string, iv = "") => {
  const promise = new Promise<CryptoJS.lib.CipherParams>((resolve, reject) => {
    const k = Hex.parse(passphrase);
    const i = iv !== "" ? Hex.parse(iv) : undefined;
    const encrypted = AES.encrypt(
      Utf8.parse(data),
      k,
      i !== undefined ? { iv: i } : undefined,
    );
    resolve(encrypted);
  });
  return await promise;
};

/**
 * Decrypts a string
 * @rgpd This function is used to decrypt data from the API
 * @param data string to decrypt
 * @param key string to use as key
 * @param iv string to use as iv
 * @returns decrypted string
 */
export const decrypt = async (data: string, key: string, iv = "") => {
  const promise = new Promise<string>((resolve, reject) => {
    const k = Hex.parse(key);
    const i = iv !== "" ? Hex.parse(iv) : undefined;
    const cipher = {
      ciphertext: Hex.parse(data),
    };
    const decrypted = AES.decrypt(
      cipher as CryptoJS.lib.CipherParams,
      k,
      i !== undefined ? { iv: i } : undefined,
    );
    resolve(Utf8.stringify(decrypted));
  });
  return await promise;
};

export const generateKey = async (length = 32) => {
  const promise = new Promise<string>((resolve, reject) => {
    const arr = random(length);
    resolve(Hex.stringify(arr));
  });
  return await promise;
};

export const encryptObject = async <T>(
  object: T,
  passphrase: string,
  iv = "",
) => {
  return await encrypt(JSON.stringify(object), passphrase, iv);
};

/**
 * Decrypts an object
 * @rgpd This function is used to decrypt data from the API
 * @param object Object to decrypt
 * @param key String to use as key
 * @param iv String to use as iv
 * @returns A promise of the decrypted object
 */
export const decryptObject = async <T>(
  object: string,
  key: string,
  iv: string,
): Promise<T> => {
  const data = await decrypt(object, key, iv);
  if (data === "") {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return {} as T;
  }
  return JSON.parse(data);
};

import { type RouteRecordRaw } from "vue-router";

export const practitionerRoutes: RouteRecordRaw[] = [
  {
    name: "AddPractitioner",
    path: "add",
    component: async () => await import("../../views/AddPractitioner.vue"),
  },
  {
    name: "UpdatePractitioner",
    path: ":practitioner?/update",
    component: async () => await import("../../views/UpdatePractitioner.vue"),
  },
];

import type { ToastMessageOptions } from "primevue/toast";
// @ts-expect-error no type for toasteventbus
import ToastEventBus from "primevue/toasteventbus";

/**
 * Toast service methods to show toast messages outside of components and setup functions.
 *
 * @module externalToast
 * @example
 * import { toast } from "./helpers/externalToast";
 *
 * toast.add({
 *  severity: "success",
 *  summary: "Success",
 *  detail: "This is a success message",
 * });
 *
 */
export const toast = {
  add: (message: ToastMessageOptions) => ToastEventBus.emit("add", message),
  removeGroup: (group: string) => ToastEventBus.emit("remove-group", group),
  removeAllGroups: () => ToastEventBus.emit("remove-all-groups"),
};

import type { AxiosError, InternalAxiosRequestConfig } from "axios";
import axios from "axios";
import { OKIDIA_API_URL } from "../config";
import { toast } from "../helpers/externalToast";
import { useAuthStore } from "../stores/auth";
import { useRequestStore } from "../stores/request";
import { type IErrorMessage, type ILoginResponse, ErrorCodes } from "./types";

export function createApiInstance() {
  return axios.create({
    baseURL: `${OKIDIA_API_URL}/api/latest`,
    responseType: "json",
    timeout: 30000,
    // withCredentials: true, // Would be better to have httpOnly cookies and not send the token in the header
  });
}

// change api for localhost in dev after bilan page
export const api = createApiInstance();

api.defaults.headers.common["Content-Type"] = "application/json";

api.interceptors.request.use(
  async (config) => {
    const requestStore = useRequestStore();
    const authStore = useAuthStore();
    // before a request is sent, incrementate the loading counter
    if (authStore.token !== null) {
      config.headers.set("Authorization", `Bearer ${authStore.token}`);
      await requestStore.incLoadingCounter();
    }
    return config;
  },
  async (error) => {
    const requestStore = useRequestStore();
    // do something with request error
    await requestStore.decLoadingCounter();
    toast.add({
      severity: "error",
      summary: "Request error",
      detail: "An error occured while sending the request",
      life: 5000,
    });
    return await Promise.reject(error);
  },
);

interface IRequestRetry {
  _retry: number;
  _maxRetries: number;
  _retryDelay: number;
}

const basicRetryConfig: IRequestRetry = {
  _retry: 0,
  _maxRetries: 3,
  _retryDelay: 1000,
};

type IRequestRetryConfig<D = any> = InternalAxiosRequestConfig<D> &
  IRequestRetry;

function getRequestConfig<D = any>(
  config: InternalAxiosRequestConfig<D>,
): IRequestRetryConfig<D> {
  return Object.assign({}, basicRetryConfig, config);
}

api.interceptors.response.use(
  async (response) => {
    const requestStore = useRequestStore();
    // do something with request error
    await requestStore.decLoadingCounter();
    return response;
  },
  async (error: AxiosError<IErrorMessage>) => {
    const requestStore = useRequestStore();
    // do something with request error
    await requestStore.decLoadingCounter();
    // When the response comes back due to an authentication error, we need to refresh the token
    if (error.config === undefined) {
      return await Promise.reject(error);
    }
    const originalRequest = getRequestConfig(error.config);
    const data = error.response?.data;
    if (
      data !== undefined &&
      originalRequest !== undefined &&
      data.code === ErrorCodes.NotLoggedIn &&
      ++originalRequest._retry < originalRequest._maxRetries &&
      originalRequest.url !== "/internal/refreshToken"
    ) {
      const data = await refreshAccessToken();
      const authStore = useAuthStore();
      await authStore.setToken(data.token, authStore.remember);
      return await api(originalRequest);
    }
    return await Promise.reject(error);
  },
);

export const refreshAccessToken = async () => {
  const { data } = await api.get<ILoginResponse>("/internal/refreshToken");
  return data;
};

export const loginUser = async (login: string, password: string) => {
  const { data } = await api.post<ILoginResponse>("/internal/login", {
    login,
    password,
    clientAppId: import.meta.env.VITE_CLIENT_APP_ID,
  });
  return data;
};

const env = import.meta.env ?? process.env ?? {};

const env_mode = "MODE" in env ? env.MODE : "production";

export const OKIDIA_APP_URL =
  "VITE_OKIDIA_APP_URL" in env
    ? env.VITE_OKIDIA_APP_URL
    : env_mode === "development"
      ? "http://localhost:5173"
      : env_mode === "staging"
        ? "https://extranet-dev.o-kidia.com"
        : env_mode === "demo"
          ? "https://extranet-demo.o-kidia.com"
          : "https://spectrum.o-kidia.com";

export const OKIDIA_FORMS_URL =
  "VITE_OKIDIA_FORMS_URL" in env
    ? env.VITE_OKIDIA_FORMS_URL
    : env_mode === "development"
      ? "http://localhost:3000"
      : env_mode === "staging"
        ? "https://forms-dev.o-kidia.com"
        : env_mode === "demo"
          ? "https://forms-demo.o-kidia.com"
          : "https://forms.o-kidia.com";

export const OKIDIA_API_URL =
  "VITE_OKIDIA_API_URL" in env
    ? env.VITE_OKIDIA_API_URL
    : env_mode === "development"
      ? "http://localhost:9004"
      : env_mode === "staging"
        ? "https://api-dev-ken.srv.o-kidia.com"
        : env_mode === "demo"
          ? "https://hcba-demo.o-kidia.com"
          : "https://api.app.srv.o-kidia.com";

export const ENV_NUMBER =
  env_mode === "development"
    ? 3
    : env_mode === "staging"
      ? 2
      : env_mode === "demo"
        ? 1
        : 0;

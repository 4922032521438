import type { MiddlewareReturn, MiddlewareContext } from "./middlewares";

/**
 * A middleware that checks if the user has the required roles. If not, it redirects to the home page. Should always be used after requireAuth.
 * @param roles Roles to check for
 * @param any Whether to check if the user has any of the roles or all of them
 * @returns a middleware function that checks if the user has the required roles
 */
export const requireRoles = (roles: string[], any = true) => {
  return async function requireRole({
    authStore,
  }: MiddlewareContext): Promise<MiddlewareReturn> {
    if (authStore.uuid === null) {
      return { type: "redirect", redirect: { name: "Login" } };
    }

    if (!authStore.hasRole(roles, any)) {
      return { type: "redirect", redirect: { name: "Home" } };
    }

    return { type: "next" };
  };
};

import { generateKey } from "../helpers/crypto";
import { parseJWT } from "../helpers/jwt";
import { api, createApiInstance } from "./api";
import { decodeContact, encodeContact } from "./contact";
import {
  type IContact,
  type IContactMinimal,
  type IContactPut,
} from "./contact.types";
import { getCrypto, postCrypto } from "./crypto";
import { type IBasicPostResponse, type IPasswordResetResponse } from "./types";
import {
  type IUserPractitionerPost,
  type IUserPractitionerPostUnencrypted,
  type IUserPractitionerPut,
  type IUserPractitionerPutUnencrypted,
  type IUserRawResponse,
  type IUserResponse,
  type IUsersRawResponse,
  type IUsersResponse,
} from "./users.types";

interface GetUsersParams {
  filterCallback?: (users: IUserRawResponse[]) => IUserRawResponse[];
}

export const getUserRaw = async (uuid: string): Promise<IUserRawResponse> => {
  const { data } = await api.get<IUserRawResponse>(`/user/${uuid}`);
  return data;
};
export const getUser = async (uuid: string): Promise<IUserResponse> => {
  const data = await getUserRaw(uuid);
  const { key } = await getCrypto("contact", data.contact.uuid);
  const [k, iv] = key.split(":");
  const contact = await decodeContact(data.contact, k, iv);
  return {
    ...data,
    contact,
  };
};

export const getUsers = async (
  entityUuid: string,
  params: GetUsersParams = {},
): Promise<IUsersResponse> => {
  // load users TODO: should manage pagination
  const { data } = await api.get<IUsersRawResponse>(
    `/users?uuid=${entityUuid}&limit=1000`,
  );

  // allow to filter before
  if (params?.filterCallback) {
    data.users = params.filterCallback(data.users);
  }

  // decode all contacts
  const users = await Promise.all(
    data.users.map(async (u) => {
      const { key } = await getCrypto("contact", u.contact.uuid);
      const [k, iv] = key.split(":");
      return {
        ...u,
        contact: await decodeContact(u.contact, k, iv),
      };
    }),
  );
  return {
    users,
    count: data.count,
  };
};

export const requestPasswordReset = async (email: string) => {
  const { data } = await api.post<IPasswordResetResponse>(
    `/internal/lost-password`,
    { email, clientAppId: import.meta.env.VITE_CLIENT_APP_ID },
  );
  return data;
};

export const resetPassword = async (token: string, password: string) => {
  const parsed = parseJWT(token);
  if (parsed === null) {
    throw new Error("Invalid token format");
  }
  const apiWithoutAuth = createApiInstance();
  apiWithoutAuth.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const { data } = await apiWithoutAuth.put(`/user/${parsed.uuid}/password`, {
    password,
  });
  return data;
};

export const addContact = async (entityUuid: string, contact: IContact) => {
  const response = await api.post<IBasicPostResponse>(
    `/entity/${entityUuid}/contact`,
    contact,
  );
  return response.data;
};

export const putContact = async (
  contactUuid: string,
  contact: IContact | IContactMinimal,
) => {
  const { data } = await api.put(`/contact/${contactUuid}`, contact);
  return data;
};

export const deleteUser = async (uuidUser: string) => {
  const { data } = await api.delete(`/user/${uuidUser}`);
  return data;
};

export const addUser = async (
  uuidEntity: string,
  practitioner: IUserPractitionerPostUnencrypted,
) => {
  const key = await generateKey(32);
  const iv = await generateKey(16);
  const { contact, ...practitionerWithoutContact } = practitioner;
  const encrypted = await encodeContact(contact, key, iv);
  const practitionerPost: IUserPractitionerPost = {
    ...practitionerWithoutContact,
    contact: encrypted.contact,
  };
  const { data } = await api.post<IBasicPostResponse>(
    `/entity/${uuidEntity}/user`,
    practitionerPost,
  );
  const user = await getUserRaw(data.uuid);
  await postCrypto({
    key: `${key}:${iv}`,
    object: "contact",
    objectUuid: user.contact.uuid,
    typeCrypto: "aes256-cbc",
  });
  return data;
};

export const putUser = async (
  user: string,
  practitioner: IUserPractitionerPutUnencrypted,
) => {
  if (practitioner.contact.uuid === undefined) {
    throw new Error("Contact uuid is undefined");
  }
  const { key } = await getCrypto("contact", practitioner.contact.uuid);
  const [k, iv] = key.split(":");
  const { contact, ...practitionerWithoutContact } = practitioner;
  const contactEncrypted = await encodeContact(contact, k, iv);
  const practitionerPut: IUserPractitionerPut = {
    ...practitionerWithoutContact,
    contact: contactEncrypted.contact as IContactPut,
  };
  const { data } = await api.put(`/user/${user}`, practitionerPut);
  return data;
};

import { AxiosError } from "axios";
import { getUser } from "../api/users";
import { toast } from "../helpers/externalToast";
import { i18n } from "../i18n";
import { type Middleware } from "./middlewares";

// Middleware to require authentication
export const requireAuth: Middleware = async ({ authStore }) => {
  // If the user is not authenticated, log them out and redirect to the login page
  if (authStore.uuid === null) {
    authStore.logout();
    return { type: "redirect", redirect: { name: "Login" } };
  }

  try {
    // Fetch the user data using the UUID from the auth store
    const user = await getUser(authStore.uuid);
    if (user === null) {
      throw new Error("User not found");
    }
    // Set the fetched user data in the auth store
    authStore.setUser(user);
  } catch (e) {
    const { t } = i18n.global;
    if (e instanceof AxiosError) {
      console.error(e.cause);
      // If the error is not a network error
      if (e.code !== AxiosError.ERR_NETWORK && e.response?.status === 401) {
        // If the status code is 401, log the user out and redirect to the login page
        authStore.logout();
        toast.add({
          severity: "error",
          detail: t("common.errors.token_expired"),
          life: 5000,
        });
        return { type: "redirect", redirect: { name: "Login" } };
      }
    }
    toast.add({
      severity: "error",
      summary: t("common.errors.verification_failed"),
      detail: t("common.errors.user_verification_failed"),
      life: 10000,
    });
  }

  // If everything is successful, proceed to the next middleware or route
  return { type: "next" };
};

import { defineStore } from "pinia";
import { useAuthStore } from "./auth";

export interface SelectEntityState {
  selectedEntity: string | null;
}

export const useSelectEntityStore = defineStore("selectEntityStore", {
  state: () => {
    const authStore = useAuthStore();
    const entity = authStore.user?.uuidEntity;
    if (entity === undefined) {
      console.error("User has no entity");
      return {
        selectedEntity: null,
      };
    }
    if (sessionStorage.getItem("selectedEntity") === null) {
      sessionStorage.setItem("selectedEntity", entity);
    }
    return {
      selectedEntity: sessionStorage.getItem("selectedEntity"),
    } satisfies SelectEntityState;
  },
  actions: {
    async setSelectedEntity(entity: string) {
      this.selectedEntity = entity;
      sessionStorage.setItem("selectedEntity", entity);
    },

    async logout() {
      this.selectedEntity = null;
      sessionStorage.removeItem("selectedEntity");
    },
  },
});

import NotFound from "../views/NotFound.vue";

import {
  createRouter,
  createWebHistory,
  type Router,
  type RouteRecordRaw,
} from "vue-router";
import { useAuthStore } from "../stores/auth";
import { useSelectEntityStore } from "../stores/selectEntity";
import { executeMiddlewares, type Middleware } from "./middlewares";

const createRoutes = (routes: RouteRecordRaw[]) => [
  {
    path: "/:locale([a-z]{2})?",
    children: routes,
  },
  { path: "/:pathMatch(.*)*", name: "NotFound", component: NotFound },
];

export const createMainRouter = (routes: RouteRecordRaw[]): Router => {
  const mainRoutes = createRoutes(routes);
  const router = createRouter({
    history: createWebHistory(),
    routes: mainRoutes,
  });

  router.beforeEach(async (to, from, next) => {
    const authStore = useAuthStore();
    if (to.meta.middlewares === undefined) {
      next();
      return;
    }

    const middlewares: Middleware[] = to.matched.flatMap(
      (route) => route.meta.middlewares ?? [],
    );
    for await (const result of executeMiddlewares(middlewares, {
      to,
      from,
      authStore,
    })) {
      if (result.type === "redirect") {
        next(result.redirect);
        return;
      }
    }
    next();
  });

  router.beforeEach(async (to, from, next) => {
    const uuidEntity = to.params.entity;
    if (uuidEntity !== undefined && uuidEntity !== "") {
      const selectStudyStore = useSelectEntityStore();
      await selectStudyStore.setSelectedEntity(
        Array.isArray(uuidEntity) ? uuidEntity[0] : uuidEntity,
      );
    }
    next();
  });
  return router;
};

<template>
  <footer class="mt-auto dark:text-gray-100">
    <div
      class="grid grid-cols-1 md:grid-cols-[250px_250px] xl:grid-cols-[250px_1fr_250px] gap-4 gap-x-0 md:gap-x-4 px-6 pt-4 pb-2 mx-auto lg:px-8 items-center xl:justify-evenly justify-around"
    >
      <p class="text-gray-600 dark:text-gray-400">
        &copy; {{ copyrightYear }} O-Kidia.
        {{ t("common.all_rights_reserved") }}
        <span>
          {{ t("common.version", { version }) }}
        </span>
      </p>
      <ul
        class="flex gap-2 col-span-2 justify-evenly xl:col-span-1 flex-col md:flex-row items-center"
      >
        <li>
          <router-link
            to="/privacy-policy"
            rel="noreferrer nofollow"
            target="_blank"
            class="font-normal text-gray-600 dark:text-gray-400 dark:hover:text-white hover:underline"
            >{{ t("routes.PrivacyPolicy") }}</router-link
          >
        </li>
        <li>
          <router-link
            to="/cgu"
            rel="noreferrer nofollow"
            target="_blank"
            class="font-normal text-gray-600 dark:text-gray-400 dark:hover:text-white hover:underline"
            >{{ t("routes.CGU") }}</router-link
          >
        </li>
        <li>
          <router-link
            to="/cgv"
            rel="noreferrer nofollow"
            target="_blank"
            class="font-normal text-gray-600 dark:text-gray-400 dark:hover:text-white hover:underline"
            >{{ t("routes.CGV") }}</router-link
          >
        </li>
        <li>
          <a
            href="mailto:support@o-kidia.com"
            rel="noreferrer nofollow"
            class="font-normal text-gray-600 dark:text-gray-400 dark:hover:text-white hover:underline"
            >{{ t("common.contact_us") }}</a
          >
        </li>
      </ul>
      <div class="row-start-2 md:col-start-2 md:row-start-1 xl:col-start-3">
        <OLanguageSwitcher :locales="localesOptions" />
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import OLanguageSwitcher from "../components/ui/OLanguageSwitcher.vue";
import { LOCALES_NAMES } from "../i18n";

withDefaults(
  defineProps<{
    version: string;
    copyrightYear?: number;
  }>(),
  {
    copyrightYear: new Date().getFullYear(),
  },
);

const { t } = useI18n();
const localesOptions = LOCALES_NAMES;
</script>

<style scoped></style>

<template>
  <Select
    v-model="selectedLocale"
    :options="localesOptions"
    option-label="label"
    option-value="value"
    :placeholder="t('common.selects.select_language')"
    fluid
  />
</template>

<script setup lang="ts">
import Select from "primevue/select";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useLocalesStore } from "../../stores/locales";

const localesStore = useLocalesStore();
const router = useRouter();

const props = defineProps<{
  locales: Record<string, string>;
}>();

const { t } = useI18n();

const selectedLocale = ref(localesStore.locale);
const localesOptions = ref(
  Object.entries(props.locales).map(([locale, name]) => {
    return {
      label: name,
      value: locale,
    };
  }),
);

const handleSelectUpdate = (value: string) => {
  selectedLocale.value = value;
  // localesStore.setLocale(value);
  const currentRouteName =
    router.currentRoute.value.name !== null &&
    router.currentRoute.value.name !== undefined
      ? router.currentRoute.value.name.toString()
      : "Home";

  router.push({
    name: currentRouteName,
    params: { ...router.currentRoute.value.params, locale: value },
  });
};

watch(selectedLocale, handleSelectUpdate);
</script>

<style scoped></style>

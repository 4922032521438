import { defineStore } from "pinia";

export interface RequestState {
  loading: boolean;
  nbLoading: number;
}

export const useRequestStore = defineStore("request", {
  state: () =>
    ({
      loading: false,
      nbLoading: 0,
    } as RequestState),
  getters: {
    isLoading(state) {
      return state.loading;
    },
  },
  actions: {
    async incLoadingCounter() {
      this.nbLoading++;
      this.loading = true;
    },
    async decLoadingCounter() {
      this.nbLoading--;
      if (this.nbLoading <= 0) {
        this.loading = false;
      }
    },
  },
});

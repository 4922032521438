import * as Sentry from "@sentry/vue";
import { App } from "vue";
import type { Router } from "vue-router";

export function createSentry(app: App, router: Router) {
  Sentry.init({
    app,
    dsn: "https://6488942abafd35aa41326bcb56c5d9aa@sentry.o-kidia.com/3",
    release: "extranet@" + __APP_VERSION__,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/hcba\.o-kidia\.com\/api/,
      /^https:\/\/hcba-demo\.o-kidia\.com\/api/,
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    // Normalize depth for the replay feature and pinia
    normalizeDepth: 10,
  });
}

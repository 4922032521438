import { defineStore, type _GettersTree } from "pinia";
import { computed, ref, watch } from "vue";
import { type IUserResponse } from "../api/users.types";
import { useCookies } from "../composables/cookies";
import { parseJWT } from "../helpers/jwt";

const { getCookie, setCookie, deleteCookie } = useCookies();

export interface IUser extends IUserResponse {}

export interface AuthStoreState {
  user: IUser | null;
  realUser: IUser | null;
  token: string | null;
  exp: number;
  uuid: string | null;
  remember: boolean;
  clientId: string | null;
}

export interface AuthStoreGetters extends _GettersTree<AuthStoreState> {
  isAuthenticated: (state: AuthStoreState) => boolean;
  hasPermission: (state: AuthStoreState) => (permission: string) => boolean;
  hasRole: (
    state: AuthStoreState,
  ) => (role: string | string[], any?: boolean) => boolean;
}

export interface AuthStoreActions {
  setUser: (user: IUser | null) => Promise<void>;
  setToken: (token: string | null, remember?: boolean) => Promise<void>;
  logout: () => Promise<void>;
  refreshToken: (token: string) => Promise<void>;
  setDummyEntity: (uuidEntity: string) => Promise<void>;
  setDummyUser: (user: IUser) => Promise<void>;
  removeDummyUser: () => Promise<void>;
}

export type AuthStore = ReturnType<typeof useAuthStore>;

class AuthStorage implements Storage {
  readonly #storage: Storage;
  private readonly available: () => boolean;

  constructor(
    storage: "localStorage" | "sessionStorage" | Storage,
    available: () => boolean = () => true,
  ) {
    this.#storage = storage instanceof Storage ? storage : window[storage];
    this.available = available;
    if (!this.available()) {
      this.clear();
    }
  }

  get length() {
    return this.#storage.length;
  }

  clear() {
    this.#storage.clear();
  }

  getItem<T>(key: string): T | null {
    if (!this.available()) {
      return null;
    }
    const value = this.#storage.getItem(key);
    if (value === null) {
      return null;
    }

    try {
      return JSON.parse(value);
    } catch (e) {
      return value as T;
    }
  }

  key(index: number) {
    if (!this.available()) {
      return null;
    }
    return this.#storage.key(index);
  }

  removeItem(key: string) {
    this.#storage.removeItem(key);
  }

  setItem<T>(key: string, value: T) {
    this.#storage.setItem(key, JSON.stringify(value));
  }
}

const authStorage = new AuthStorage(
  "localStorage",
  () => getCookie("token") !== null,
);

export const useAuthStore = defineStore("auth", () => {
  const token = ref(getCookie("token"));
  watch(token, (value) => {
    if (value === null) {
      deleteCookie("token");
      return;
    }
    setCookie("token", value, remember.value ? exp.value : 0);
  });

  const remember = ref(authStorage.getItem<boolean>("remember") ?? false);
  watch(remember, (value) => authStorage.setItem("remember", value));

  const parsedToken = computed(() =>
    token.value !== null ? parseJWT(token.value) : null,
  );

  const cookieUser = ref(
    token.value !== null ? authStorage.getItem<IUser>("user") : null,
  );
  watch(cookieUser, (value) => {
    if (value === null || token.value === null) {
      authStorage.removeItem("user");
      return;
    }
    authStorage.setItem("user", value);
  });
  const dummyUser = ref(
    token.value !== null ? authStorage.getItem<IUser>("dummyUser") : null,
  );
  watch(dummyUser, (value) => {
    if (value === null || token.value === null) {
      authStorage.removeItem("dummyUser");
      return;
    }
    authStorage.setItem("dummyUser", value);
  });

  const user = computed(() =>
    dummyUser.value !== null &&
    cookieUser.value !== null &&
    cookieUser.value.roles.includes("global_admin")
      ? dummyUser.value
      : cookieUser.value,
  );
  const realUser = computed(() =>
    dummyUser.value !== null &&
    cookieUser.value !== null &&
    cookieUser.value.roles.includes("global_admin")
      ? cookieUser.value
      : null,
  );

  const exp = computed(() =>
    parsedToken.value !== null ? parsedToken.value.exp : 0,
  );
  const uuid = computed(() =>
    parsedToken.value !== null ? parsedToken.value.uuid : null,
  );
  const clientId = computed(() =>
    parsedToken.value !== null ? parsedToken.value.aud : null,
  );

  const isAuthenticated = computed(
    () => !!user.value || (token.value !== null && token.value !== ""),
  );
  const hasPermission = (permission: string) => {
    if (user.value === null) {
      return false;
    }
    if (user.value.roles.includes("global_admin")) {
      return true;
    }
    if (user.value.effectiveACLs.includes(permission)) {
      return true;
    }
    return false;
  };
  const hasRole = (role: string | string[], any = true) => {
    if (user.value === null) {
      return false;
    }
    const roles = Array.isArray(role) ? role : [role];
    if (any) {
      return roles.some((r) => user.value!.roles.includes(r));
    }
    return roles.every((r) => user.value!.roles.includes(r));
  };

  const setUser = (u: IUser | null) =>
    (cookieUser.value = token.value !== null ? u : null);
  const setToken = (t: string | null, r = false) => {
    remember.value = r;
    token.value = t;
  };

  function logout() {
    cookieUser.value = null;
    dummyUser.value = null;
    token.value = null;
  }

  function setDummyEntity(uuidEntity: string) {
    if (realUser.value === null) {
      return;
    }
    if (realUser.value.roles.includes("global_admin")) {
      dummyUser.value = {
        ...realUser.value,
        uuidEntity,
      };
    }
  }

  function setDummyUser(u: IUser | null) {
    if (realUser.value === null) {
      return;
    }
    if (realUser.value.roles.includes("global_admin")) {
      dummyUser.value = u;
    }
  }

  return {
    token,
    remember,
    user,
    realUser,
    exp,
    uuid,
    clientId,
    isAuthenticated,
    hasPermission,
    hasRole,
    setUser,
    setToken,
    logout,
    setDummyEntity,
    setDummyUser,
  };
});

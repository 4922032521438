import { defineStore } from "pinia";

export interface LocalesState {
  locale: string;
}

export const useLocalesStore = defineStore("locales", {
  state: (): LocalesState => ({
    locale: localStorage.getItem("locale") ?? "fr",
  }),
  getters: {
    getLocale(state) {
      return state.locale;
    },
  },
  actions: {
    async setLocale(locale: string) {
      this.locale = locale;
      localStorage.setItem("locale", locale);
    },
  },
});

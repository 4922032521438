import { type AuthStore } from "../stores/auth";
import type { RouteLocationRaw, RouteLocationNormalized } from "vue-router";

export interface MiddlewareContext {
  to: RouteLocationNormalized;
  from: RouteLocationNormalized;
  authStore: AuthStore;
}

interface MiddlewareReturnNext {
  type: "next";
}

interface MiddlewareReturnRedirect {
  type: "redirect";
  redirect: RouteLocationRaw;
}

export type MiddlewareReturn = MiddlewareReturnNext | MiddlewareReturnRedirect;

export type Middleware = (
  context: MiddlewareContext,
) => Promise<MiddlewareReturn>;

export async function* executeMiddlewares(
  middlewares: Middleware[],
  context: MiddlewareContext,
) {
  for (const middleware of middlewares) {
    yield middleware(context);
  }
}

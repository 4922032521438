import { type RouteRecordRaw } from "vue-router";

export const runRoutes: RouteRecordRaw[] = [
  {
    name: "ViewRun",
    path: ":run",
    component: async () => await import("../../views/ViewRun.vue"),
  },
  {
    name: "RunCreateForm",
    path: ":run/create-form",
    component: async () => await import("../../views/CreateForm.vue"),
    props: true,
  },
];

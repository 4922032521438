import { type RouteRecordRaw } from "vue-router";
import { renameNameRecursive } from "../helpers";
import { userRoutes } from "./users";
import { practitionerRoutes } from "./practitioners";

export const organisationRoutes: RouteRecordRaw[] = [
  {
    name: "Organisation",
    path: ":entity",
    component: async () => await import("../../views/OrganisationView.vue"),
  },
  {
    path: ":entity/user",
    children: renameNameRecursive("User", userRoutes),
  },
  {
    path: ":entity?/practitioner",
    children: renameNameRecursive("Practitioner", practitionerRoutes),
  },
];

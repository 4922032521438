<template>
  <Icon
    :icon="props.icon"
    :inline="props.inline"
    :class="[{ inline: props.inline }]"
  />
</template>

<script setup lang="ts">
import { Icon } from "@iconify/vue";

const props = withDefaults(
  defineProps<{
    /** Icon name (from https://icon-sets.iconify.design/fa6-solid/) */
    icon: string;
    /** Is a inline icon or not */
    inline?: boolean;
  }>(),
  {
    inline: true,
  },
);
</script>
